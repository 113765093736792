import React from "react"
import WhyAia from "../components/whyAia/WhyAia"

const whyaia = () => {
  return (
    <div>
      <WhyAia />
    </div>
  )
}

export default whyaia
