import React from "react"
import Testimonials from "../home/testimonials/Testimonials"
import Subscription from "../home/subscriptionSection/Subscription"
import TitleBanner from "../titleBanner/TitleBanner"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import "./whyaia.scss"
import { graphql, useStaticQuery } from "gatsby"
import { Link as GatsbyLink } from "gatsby"

const WhyAia = () => {
  const backgroundImage = useStaticQuery(
    graphql`
      query {
        background1: file(
          relativePath: { eq: "why-about-bg.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 584) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        background2: file(
          relativePath: { eq: "vision-bg.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 455) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  const BGImage1 =
    backgroundImage.background1.childImageSharp.fluid
  const BGImage2 =
    backgroundImage.background2.childImageSharp.fluid

  return (
    <div>
      <TitleBanner
        title="Why Ace Industrial Academy?"
        description="What makes Ace Industrial Academy stand out from the crowd?"
        withOnlyDescription
        hasPlainBanner
      />
      {/* --- Whyus Section --- */}
      <BackgroundImage
        className="whyabout-bg"
        fluid={BGImage1}
      >
        <section className="whyus_section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-md-7 col-lg-6">
                <div className="left-box">
                  <div className="section-header">
                    <h2>
                      We offer training in a wide range of
                      BIM and CAD tools, across multiple
                      construction disciplines
                    </h2>
                  </div>
                  <div className="content">
                    <p>
                      We recognize that companies may use
                      unique selections of BIM and CAD
                      software depending on their current
                      assets, nature of project and project
                      scope. Ace Industrial Academy’s robust
                      suite of BIM and CAD courses develops
                      and grows aspiring and existing
                      practitioners looking to upskill or
                      reskill according to their project or
                      job requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-5 col-lg-6">
                <div className="right-box">
                  <div className="main-img">
                    <StaticImage
                      src="../../assets/images/whyus/whyus-mobile.png"
                      className="web-none"
                      alt="Why Us"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      {/* --- courses_list --- */}
      <section className="courses_list">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-box">
                <h3>We offer courses in</h3>
                <div className="list">
                  <ul>
                    <li>
                      <a href="#!">Auto CAD</a>
                    </li>
                    <li>
                      <a href="#!">Revit</a>
                    </li>
                    <li>
                      <a href="#!">Navisworks</a>
                    </li>
                    <li>
                      <a href="#!">OpenBuildings</a>
                    </li>
                    <li>
                      <a href="#!">OpenPlant</a>
                    </li>
                    <li>
                      <a href="#!">OpenRail</a>
                    </li>
                    <li>
                      <a href="#!">IFC-SG</a>
                    </li>
                    <li>
                      <a href="#!">BIM Coordination</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-10 col-md-12 col-lg-12">
              <div className="content-box">
                <div className="content">
                  <p>
                    Psst. Also, that’s not all. If you’re
                    looking for something that is not listed
                    on our website, give us a buzz. We might
                    just have what you’re looking for in our
                    back pockets
                  </p>
                  <div className="btn-box">
                    <GatsbyLink to="/contact">
                      Contact Us Now
                    </GatsbyLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --- whyus process 1 --- */}
      <section className="whyus_proccess section_one zircon-bg">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-12 col-lg-6">
              <div className="img-box">
                <div className="proc-one">
                  <StaticImage
                    src="../../assets/images/whyus/process1.webp"
                    alt="WhyUs Process"
                  />
                </div>
                <div className="acra-logo">
                  <StaticImage
                    src="../../assets/images/whyus/acra.svg"
                    className="small-icon"
                    alt="ACRA"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-md-12 col-lg-6">
              <div className="content-box">
                <div className="section-header">
                  <h2>We adapt to the market</h2>
                </div>
                <div className="content">
                  <p>
                    Construction IT continues to evolve in
                    its people, process, and technology.
                    Over 200 of{" "}
                    <a
                      href="https://www.aceplp.com.sg/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Ace Group’s
                    </a>{" "}
                    BIM Engineers serves the industry across
                    projects, stakeholders and construction
                    disciplines. Our outreach into the
                    market enables us to be in the know of{" "}
                    <GatsbyLink to="/courses/ifc-training">
                      new digital delivery requirements{" "}
                    </GatsbyLink>
                    and understand how Built Environment
                    companies use digital tools. This drives
                    us to curate highly relevant and
                    practical courses for you. Why? Because
                    time is limited, we’re up to our necks
                    in work, and it is critical that we
                    develop courses that allows for high
                    transference of learning to your
                    projects.{" "}
                  </p>
                  <p>
                    Ace Group also provides{" "}
                    <a
                      href="https://www.aceplp.com.sg/bim-services/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      hiring solutions
                    </a>{" "}
                    for companies looking for candidates who
                    are job-ready and possess in-demand
                    skills.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --- whyus process 2 --- */}
      <BackgroundImage
        className="why-proccess-bg"
        fluid={BGImage2}
      >
        <section className="whyus_proccess section_two">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-md-12 col-lg-6">
                <div className="content-box">
                  <div className="section-header">
                    <h2>
                      We are a team of adult learning
                      specialists
                    </h2>
                  </div>
                  <div className="content">
                    <p>
                      As part of our commitment to deliver
                      the highest quality learning
                      experience, our team maintains
                      certification in adult learning and
                      continuing education and curriculum
                      development and assessment. Learners
                      who undertake our courses are highly
                      motivated, self-driven, purposeful,
                      and results oriented. Our team of
                      instructional designers and subject
                      matter experts explore methodologies,
                      tool and techniques to maximize the
                      effectiveness of training delivery. We
                      seek to create a conducive learning
                      environment that allows you to tap on
                      and share your project experiences and
                      goals with like-minded professionals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-12 col-lg-6">
                <div className="img-box">
                  <StaticImage
                    src="../../assets/images/whyus/process2.webp"
                    alt="WhyUs Process"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      {/* --- whyus process 3 --- */}
      <section className="whyus_proccess section_three zircon-bg">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12 col-lg-6">
              <div className="img-box">
                <StaticImage
                  src="../../assets/images/whyus/process3.webp"
                  alt="WhyUs Process Three"
                />
              </div>
            </div>
            <div className="col-xl-6 col-md-12 col-lg-6">
              <div className="content-box">
                <div className="section-header">
                  <h2>
                    We invest in access and opportunity
                  </h2>
                </div>
                <div className="content">
                  <p>
                    In 2022, Ace Industrial Academy is
                    recognized as a SkillsFuture Approved
                    Training Provider. This enables us to
                    deliver industry-relevant courses while
                    keeping course fees accessible to
                    Singaporeans and Permanent Residents. As
                    Singapore’s Built Environment continues
                    to digitalize, companies and individuals
                    can tap on our courses to uplift their
                    digital competencies and thrive in an
                    increasingly technological industry. We
                    also continue to contribute back to the
                    larger community through our free
                    community training class, who are made
                    of learners considering a career in the
                    Built Environment, or are existing
                    professionals interested in being
                    re-skilled.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <Subscription />
    </div>
  )
}

export default WhyAia
